import React, { Component, useState, useEffect } from "react";
import PageHelmet from "../../component/common/Helmet";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import ModalVideo from './modal-video/ModalVideo';
import { FiCode, FiChevronUp} from "react-icons/fi";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import Lightbox from 'react-image-lightbox';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import 'react-image-lightbox/style.css';
import { useMediaQuery } from "react-responsive";

;


const images = [
    {
        original: '/imagenes/pokerenglish/1.jpeg',
        thumbnail: '/imagenes/pokerenglish/1.jpeg'
      },
   
    {
        original: '/imagenes/pokerenglish/2.png',
        thumbnail: '/imagenes/pokerenglish/2.png',
      },
      {
        original: '/imagenes/pokerenglish/3.png',
        thumbnail: '/imagenes/pokerenglish/3.png',
      },
      {
        original: '/imagenes/pokerenglish/3.5.png',
        thumbnail: '/imagenes/pokerenglish/3.5.png',
      },
      {
        original: '/imagenes/pokerenglish/3.6.png',
        thumbnail: '/imagenes/pokerenglish/3.6.png',
      },
      
      

      {
        original: '/imagenes/pokerenglish/6.png',
        thumbnail: '/imagenes/pokerenglish/6.png',
      },
      {
        original: '/imagenes/pokerenglish/7.png',
        thumbnail: '/imagenes/pokerenglish/7.png',
      },
      {
        original: '/imagenes/pokerenglish/8.png',
        thumbnail: '/imagenes/pokerenglish/8.png',
      },
      {
        original: '/imagenes/pokerenglish/9.png',
        thumbnail: '/imagenes/pokerenglish/9.png',
      },
      {
        original: '/imagenes/pokerenglish/10.png',
        thumbnail: '/imagenes/pokerenglish/10.png',
      },
      {
        original: '/imagenes/pokerenglish/11.png',
        thumbnail: '/imagenes/pokerenglish/11.png',
      },
     
    
  ];



const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]


const TabOne = [
    {
        image: '01',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-01.jpg',
        category: 'Web Design',
        title: 'Design is a creative part'
    },
    {
        image: '02',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
        category: 'Mobile App',
        title: 'The service provide for designer'
    },
    {
        image: '03',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-03.jpg',
        category: 'Web Design',
        title: 'Mobile App landing Design'
    },
    {
        image: '04',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-04.jpg',
        category: 'Mobile App',
        title: 'Logo Design creativity'
    },
    {
        image: '05',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-05.jpg',
        category: 'Web Design',
        title: 'T-shirt design is the part of design'
    },
    {
        image: '06',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-06.jpg',
        category: 'Logo Design',
        title: 'Getting tickets to the big show'
    },
]

const PokerEnglish = () => {
    const esTelefono = useMediaQuery({
        query: "(max-width: 640px)",
      })

    useEffect(()=>{

        window.scrollTo(0, 0);

    },[])
    
   const [abrirModal, setAbrirModal] = useState({
       estaAbierto: false,
       linkVideo: ''
   }) 

   



   const [galeria, setGaleria] = useState({
    tab1: 0,
    isOpen: false,

   })

   let {tab1, isOpen} = galeria;

    return (
        
        <>
        <PageHelmet pageTitle='Portfolio Details' />

        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
        
        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image " style={{ backgroundImage: "url(" + "/assets/images/bg/bg-image-4-w201.jpeg" + ")"}} data-black-overlay="7">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="rn-page-title text-center pt--100">
                            <h2 className="title theme-gradient">Poker English</h2>
                            <p>Plataforma de educacion online</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details pt--120 bg_color--1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="portfolio-details">
                            <div className="row row--35 mb--50">
                                <div className="col-lg-6">
                                    <div className="inner">
                                        <div className="section-title">
                                            <h2 className="title">Informacion </h2>
                                            {/*  
                                            <div style={{display:'flex', marginTop:30}}>
                                            <img src='/imagenes/copera19/imagenes/2.jpeg' style={{width:'100%', borderRadius:20, }}></img>
                                            </div>
                                        */}

                                            <p className="description mt--30">PokerEnglish es un servicio de clases de ingles online con maestros experimentados que mediante su plataforma para alumnos online contamos con un metodo unico de enseñanza basado en cartas</p>
                                            <p className="description">Para este proyecto me encargue de programar la plataforma para alumnos, la landing page del proyecto, la plataforma de maestros (para dar de alta alumnos, subir material, crear grupos, desbloquear cartas, pizarra de apoyo, calificaciones etc.. un sistema escolar en pocas palabras)</p>
                                        </div>
                                        
                                        <div className="portfolio-details-btn mt--30">
                                            <a className="btn-default btn-border" target="_blank" href="https://pokerenglish.com/">Pruebalo en web</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                        <div className="inner">
                                            <div className="section-title">
                                                <h4 className="title">Technologies principales</h4> 
                                                <ul className="list-style--1">
                                                    <li><FiCode /> Firebase</li>
                                                    <li><FiCode /> Typescript</li>
                                                    <li><FiCode /> React</li>
                                                    <li><FiCode /> Next.js</li>
                                                </ul> 
                                                <h4 className="title">Redes Sociales</h4> 
                                                <ul className="list-style--1">
                                                <li style={{cursor:'pointer'}} onClick={()=> window.open('https://www.facebook.com/pokerenglish')} ><FaFacebookSquare style={{fontSize:25}} /> Facebook</li>
                                                    <li style={{cursor:'pointer'}} onClick={()=> window.open('https://www.linkedin.com/company/74975338')}><FaLinkedin  style={{fontSize:25}}/> LinkedIn</li>
              
                                                </ul> 
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Portfolio Details */}

   

                            <div>
                                    
                                </div>

           {/* VIDEOS Y FOTOS  */}
           <div className="rn-portfolio-area pb--120 bg_color--1 line-separator">
            <div className="container">
                <div className="row">

                    {/* VIDEO EXPLICACION WEB*/}

                    <div className="col-lg-6" >
                            
                            <div className="item-portfolio-static">
                                <div >
                                    <div className="portfolio-static">
                                        <div className="thumbnail-inner">
                                            <div className="thumbnail  position-relative mb--30">
                                                <a href="#portfolio-details">
                                                    <img style={{height: esTelefono ? 193.17: 400, }} src={`/imagenes/pokerenglish/pokervideo.png`} alt="Portfolio Images"/>
                                                   
                                    <button className="video-popup position-top-center" onClick={() => setAbrirModal({

                                        estaAbierto: true,
                                        linkVideo: 'https://firebasestorage.googleapis.com/v0/b/pruebas-6019b.appspot.com/o/pokerweb_PTxIwH8e.mp4?alt=media&token=ac4ca3f1-ae3c-4ab2-b375-feb8d5f283f1',

                                    })}><span className="play-icon"></span></button>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <p>Landing Page</p>
                                                <h4><a href="#portfolio-details">Pagina web de Poker English</a></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    
                        
                {/*  
                    {TabOne.map((value , index) => (
                        <div className="col-lg-6" key={index}>
                            {isOpen && (
                                <Lightbox
                                    mainSrc={TabOne[tab1].bigImage}
                                    nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                    prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                    onCloseRequest={() => setGaleria({...galeria,['isOpen']:false})}
                                    onMovePrevRequest={() =>
                                        setGaleria({
                                            ...galeria,
                                            ['tab1']: (tab1 + TabOne.length - 1) % TabOne.length,
                                        })
                                   
                                    }
                                    onMoveNextRequest={() =>
                                        setGaleria({
                                            ...galeria,
                                            ['tab1']: (tab1 + 1) % TabOne.length
                                        })
                                 
                                    }
                                />
                            )}
                            <div className="item-portfolio-static">
                                <div onClick={() =>setGaleria({ isOpen: true, tab1: index })}>
                                    <div className="portfolio-static">
                                        <div className="thumbnail-inner">
                                            <div className="thumbnail">
                                                <a href="#portfolio-details">
                                                    <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.jpg`} alt="Portfolio Images"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <p>{value.category}</p>
                                                <h4><a href="#portfolio-details">{value.title}</a></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

*/}

                       {/* GALERIA DE FOTTOS */}

                           
                 
                </div>
                <div style={{
                    marginTop:70,
                    
                }}>
                    <div style={{
                        marginBottom:30,
                        display:'flex',
                        justifyContent:'center'
                    }}>
                 <h4 >Galeria</h4>
                 </div>
                <ImageGallery 
                items={images} />
                </div>
            </div>
        </div>
        {/* End Portfolio Area  */}


        {/* Start Back To Top */}
        <div className="backto-top">
            <ScrollToTop showUnder={160}>
                <FiChevronUp />
            </ScrollToTop>
        </div>
        {/* End Back To Top */}
        
        <Footer />  
        <ModalVideo
         abrirModal={abrirModal}
         setAbrirModal={setAbrirModal}
         fotoPortada={abrirModal.fotoPortada}
         linkVideo={abrirModal.linkVideo}
        />
         </>
      );
}
 
export default PokerEnglish;


