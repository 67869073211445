import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Particles from 'react-particles-js';
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import { Link } from 'react-router-dom';
import PortfolioMasonry from '../elements/portfolio/PortfolioMasonry';
import PortfolioFreelance from '../elements/portfolio/PortfolioFreelance';
import Lottie from 'react-lottie';
import primero from '../../lottie/primero.json';
import { useMediaQuery } from "react-responsive";



const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: primero,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
   
  };

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to Luis Romo Dev',
        description: '',
        buttonText: 'Download CV',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    const esTelefono = useMediaQuery({
        query: "(max-width: 640px)",
      })

    let title = 'About me',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered in some form, by injected humour.';
    const PostList = BlogContent.slice(0 , 3);
    return (
        <div className="active-dark bg_color--9">
            <Helmet pageTitle="Portfolio Landing" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            
            {/* Start Slider Area   */}
            <div id="home" className="fix  ">
            {/* <div className="frame-layout__particles">
                        
                    </div>
                */}
               
                <div className="slider-wrapper ">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div style={{paddingTop:100, paddingBottom:100}} className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <Particles
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: "0",
                                left: "0"
                              }}
                            params={{
                                "particles": {
                                    "number": {
                                        "value": esTelefono  ?40 : 100
                                        
                                    },
                                    "size": {
                                        "value": 3
                                    }
                                },
                                "interactivity": {
                                    "events": {
                                        'onresize': {
                                            'enable': true,
                                            'density_auto': true,
                                            'density_area': 400 // nb_particles = particles.nb * (canvas width *  canvas height / 1000) / density_area
                                          },
                                        "onhover": {
                                            
                                            "enable": true,
                                            "mode": "repulse"
                                        }
                                    }
                                }
                            }}
                        />
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div  className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">I'm Luis Romo<br/>
                                            <TextLoop>
                                                <span> Full Stack Developer</span>
                                                <span> Entrepreneur</span>
                                                <span> Tech Lead</span>
                                               
                                            </TextLoop>{" "}
                                            </h1>
                                            <h2>Born in Guadalajara, Mexico.</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn mt--30"><a className="btn-default btn-border btn-opacity" target='_blank' href='https://firebasestorage.googleapis.com/v0/b/pruebas-6019b.appspot.com/o/LUIS%20EDUARDO%20romo%20fernandez%20(1).pdf?alt=media&token=9b6bc132-22c4-40c5-acd1-e450dac21bb3'>{value.buttonText}</a></div> : ''}
                                        </div>
                                       
                                        <div>
                                   
                                            </div>
                                    </div>
                                    <div className='col-lg-5'>

                                    <Lottie 
	    options={defaultOptions}
        height={550}
        width={'100%'}
      />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            
            
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about" className="fix">
            
                <div className="about-area ptb--120  bg_color--8">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/luisromodev.jpeg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7"> 
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span className="subtitle">Who am I?</span>
                                            <h2 className="title mb--20">{title}</h2>
                                            <p className="description mt_dec--20">My name is Luis Romo. I am a former CTO, Tech Lead and Self-taught Full Stack Developer with more than 5 years of experience both as a freelancer and for entrepreneurship and personal projects. I have won competitions at the national and state levels, in addition to participating. My main objective is to help society through software and derivatives, I am to serve and help you.</p>
                                        </div>
                                        <div className="row mt--40">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* SKILLS */}
            <div id="skills" className="fix" >
                <div className="service-area creative-service-wrapper pb--120 bg_color--8" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <span className="subtitle">Which are my skills?</span>
                                    <h2 className="title">Skills</h2>
                                    <p className="description">I am a self-taught person who improves daily<br /> These are my current skills</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="20" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            {/* End Service Area  */} 


                             {/* Technologies */}
            <div id="tecnologias" className="fix">
                <div className="service-area creative-service-wrapper pb--120 bg_color--8" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <span className="subtitle">What are the technologies that i use?</span>
                                    <h2 className="title">Technologies</h2>
                                    <p className="description">Currently these are the technologies <br /> with whom I work, however I continue to learn every day</p>
                                </div>
                                <div>
                                    <img
                                    style={{borderRadius:15, marginTop:10}}
                                    src={'https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665479640/default_utils_images/Luis_Romo_Tech_Stack_1_osat5i.png'}
                                    ></img>
                                </div>
                            </div>
                        </div>
        
                    </div>
                </div>  
            </div>
            {/* End Service Area  */} 



                 {/* Start portfolio Area  EMPRENDIMIENTO */}
                 <div id='portfolio' className="rn-portfolio-area bg_color--8 ptb--120" style={{paddingTop:0}}>
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">My work as an entrepreneur</span>
                                    <h2 className="title">Projects and entrepreneurship</h2>
                                    <p className="description">These are my personal projects<br /> i invite you to click and learn more about them</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30" style={{marginBottom:100}}>
                        <div className="row">
                            <PortfolioMasonry item="8" column="col-lg-6 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
            
                    </div>

                        {/* TRABAJOS A CLIENTES */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">My work as freelancer</span>
                                    <h2 className="title">Works to clients</h2>
                                    <p className="description">These are some of the works I have done for individuals <br /> i have worked for both individuals and companies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PortfolioFreelance item="8" column="col-lg-6 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                        <div className="row">
                           
                        </div>
                    </div>
           
                </div>
                {/* End portfolio Area  */}

                   

           
            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area pb--120 bg_color--8">
                    <ContactThree contactImages="/assets/images/about/about-13.jpg" contactTitle="Hire Me." />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;
