import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Player } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css"; // import css


export default function ModalVideo({abrirModal, setAbrirModal, linkVideo}) {
  

  return (
    <div>
      
      <Dialog
      fullWidth={true}
      maxWidth='md'
        open={abrirModal.estaAbierto}
        onClose={()=>{
          setAbrirModal({
            estaAbierto:false,
            linkVideo:''

          })
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
        style={{padding:0}}
        >
        <Player
      playsInline
      src={linkVideo}
    />
         
        </DialogContent>
        
      </Dialog>
    </div>
  );
}
