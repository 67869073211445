import React ,{ Component }from "react";
import { FiCast , FiSettings,FiCode,
    FiCloud,
    FiSmartphone, FiEdit2,FiLayers , FiPieChart,FiUsers , FiClipboard, FiMonitor } from "react-icons/fi";

    import { GiCircuitry } from "react-icons/gi";

const ServiceList = [
    {
        icon: <FiCode />,
        title: 'Software Development',
        description: 'Any type of system, whether web application, desktop, ecommerce, points of sale, logistics, etc... '
    },
    {
        icon: <FiSmartphone />,
        title: 'Mobile application development',
        description: 'Mobile applications for both android and IOS'
    },
    {
        icon: <FiMonitor />,
        title: 'Development of web pages',
        description: 'Programming, design, hosting and subsequent deployment'
    },
    ,
    {
        icon: <FiSettings />,
        title: 'Mechatronic',
        description: 'Experience in sensors of all kinds, robotics and electronics projects'
    },
    ,
    {
        icon: <FiCast />,
        title: 'Internet of things',
        description: 'Thanks to the experience in software development and mechatronics I can do any project related to IOT'
    },
    ,
    {
        icon: <FiCloud />,
        title: 'Cloud Computing',
        description: 'Google Coud, AWS, IBM and Microsoft Azure'
    },
    ,
    {
        icon: <FiEdit2/>,
        title: 'Mechanical design',
        description: 'Sketch, design, calculations and animations mainly in Solidworks'
    },
    { 
        icon: <FiClipboard />,
        title: 'Project management',
        description: 'Agile methodologies, planning and revision, I am in charge of specifying all the processes 100%'
    },
    {
        icon: <GiCircuitry />,
        title: 'Machine Learning',
        description: 'Development of chatbots, NLP, Artificial Vision and derivatives'
    },
    { 
        icon: <FiPieChart />,
        title: 'Data management and analytics',
        description: 'Through the use of Google Analytics, Dashboards etc. I like that my clients and projects have their measurable data'
    }
   
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/">
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
