import React, { Component, useState } from "react";
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';
import Lottie from 'react-lottie';
import segundo from '../../../lottie/segundo.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: segundo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };



const ContactThree = ({contactImages}) => {

    const [datos, setDatos] = useState({
        nombre: '',
        correo: '',
        tema: '',
        mensaje: ''
    })


    const onChange = (e)=>{

        setDatos({
            ...datos,
            [e.target.name]: e.target.value
        })
    }

    const enviarEmail = () => {
        let {nombre,correo,tema,mensaje} = datos;


        let data1 = {
          to_email: "contact@luisromodev.com",
          message1: "Nombre: " + nombre,
          message2: "Telefono: " + correo,
          message3: "Email: " + tema,
          message4: "Asunto: " + mensaje,
        };
    
        emailjs
          .send(
            "service_6n67cql",
            "template_8vxzndn",
            data1,
            "ZLgRCSJ8C5-sCvQjP"
          )
          .then(
            function (response) {
              console.log("todo bien");
            },
            function (err) {
                console.log(err)
              console.log("Hubo un erros");
            }
          );
      };
    

    const enviarDatos = (e)=>{
        e.preventDefault()

        let {nombre,correo,tema,mensaje} = datos;
        
        if(nombre !== '' &&  correo !== '' &&  
        tema !== '' &&  mensaje !== ''  ){
          
            enviarEmail();
            setDatos({
                nombre:'',
                correo:'',
                tema: '',
                mensaje: ''
            })
            Swal.fire({
                title: 'Tu mensaje ah sido enviado correctamente',
                icon: 'success'
            })

        }else{
            Swal.fire({
                title: 'Fill in all the information to be able to send the message',
                icon: 'warning'
            })

        }
    }


    return ( 

        <div className="contact-form--1">
        <div className="container">
            <div className="row row--35 align-items-start">
                <div className="col-lg-6 order-2 order-lg-1">
                    <div className="section-title text-left mb--50">
                        <span className="subtitle">Is there anything I can help you with?</span>
                        <h2 className="title">Contact me</h2>
                        <div className="im_address_inner">
                            <div className="im_address">
                                <span>Call or whatsapp:</span>
                                <a className="link im-hover" href="tel:+52 3329568558">+52 3329568558</a>
                            </div>
                            <div className="im_address mt--5">
                                <span>Email:</span>
                                <a className="link im-hover" href="mailto:contact@luisromodev.com">contact@luisromodev.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <form onSubmit={enviarDatos}>
                            <label>
                                <input
                                    type="text"
                                    name="nombre"
                                    value={datos.nombre}
                                    onChange={onChange}
                                    placeholder="Your name"
                                />
                            </label>

                            <label >
                                <input
                                    name="correo"
                                    value={datos.correo}
                                    onChange={onChange}
                                    placeholder="your email"
                                />
                            </label>

                            <label htmlFor="item03">
                                <input
                                    type="text"
                                    name="tema"
                                    value={datos.tema}
                                    onChange={onChange}
                                    placeholder="write the subject"
                                />
                            </label>
                            <label htmlFor="item04">
                                <textarea
                                    type="text"
                                    id="item04"
                                    name="mensaje"
                                    value={datos.mensaje}
                                    onChange={onChange}
                                    placeholder="your message"
                                />
                            </label>
                            <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Send</button>
                        </form>
                    </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                    <div className="thumbnail mb_md--30 mb_sm--30">
                    <Lottie 
	    options={defaultOptions}
        
        width={'100%'}
      />
                  
                    </div>
                </div>
            </div>
        </div>
    </div>


     );
}
 
export default ContactThree