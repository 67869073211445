import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const Portfolio_image = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665876816/default_utils_images/portada_3_qjzymo.png" alt="React Creative Agency" />;
const Portfolio_image2 = <img style={{height:440}}src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665876817/default_utils_images/portada_2_jgzxhw.png"alt="React Creative Agency" />;
const Portfolio_image3 = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665876818/default_utils_images/portada_1_mwaitx.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img style={{height:440}} src="/imagenes-clientes/yess/portada.png" alt="React Creative Agency" />;
const Portfolio_image5 = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665876819/default_utils_images/portada_1_wkk37t.png" alt="React Creative Agency" />;
const Portfolio_image6 = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665876821/default_utils_images/portada_zqth7f.png" alt="React Creative Agency" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/portfolio-static-07.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-static-09.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;



const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Work done: points of sale for branches',
        title: 'Grupo Ferny Llantas',
        description: 'Company dedicated to the sale of tires',
        link:'/ferny-llantas'
    },
    {
        image: Portfolio_image2,
        category: 'Work done: ecommerce and dashboard for the company',
        title: 'Emerymark',
        description: '#1 ice cream machine company in Latin America',
        link:'/emerymark'
    },
    {
        image: Portfolio_image3,
        category: 'Work done: System to quote moving services, dashboard for the company',
        title: 'Mudanzas Gana',
        description: 'moving services company',
        link:'/'
    },
    
    {
        image: Portfolio_image4,
        category: 'Work done: mobile application, dashboard for the company and dashboard for associates',
        title: 'Yes Fitness',
        description: 'Membership to schedule fitness classes ',
        link:'/'
    },
   
    {
        image: Portfolio_image5,
        category: 'Work done: Point of sale for restaurant',
        title: 'D´bary',
        description: 'Food restaurant',
        link:'/'
    },
    
    {
        image: Portfolio_image6,
        category: 'artist website',
        title: 'Erick Alvarez',
        description: 'artist website',
        link:'/'
    },
    
    
    
]


class PortfolioFreelance extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (

value.link === '/' ?

<div className={`${column}`} key={index} onClick={()=>{
 Swal.fire({
     title: `${value.title === 'GaiaSoft' ?
     'Gaia Soft was a venture that lasted 6 months which was a company dedicated to software services and product creation, a company in which I was co-founder'
     :

     'This project is finished however the link of the project is under maintenance, the information will be available very soon'}`
   
 })
}}>
<div className="im_portfolio">
    <div className="thumbnail_inner">
        <div className="thumbnail">
            <a>
                {value.image}
            </a>    
        </div>
    </div>
    <div className="content">
        <div style={{cursor:'pointer'}} className="inner">
            <div className="portfolio_heading">
                <div className="category_list">
                    <a>{value.category}</a>
                </div>
                <h4 className="title"><a>{value.title}</a></h4>
            </div>
            <div className="portfolio_hover">
                <p>{value.description}</p>
            </div>
        </div>
    </div>
  
</div>
</div>





:

 <div className={`${column}`} key={index}>
     <div className="im_portfolio">
         <div className="thumbnail_inner">
             <div className="thumbnail">
                 <Link to="/portfolio-details">
                     {value.image}
                 </Link>    
             </div>
         </div>
         <div className="content">
             <div className="inner">
                 <div className="portfolio_heading">
                     <div className="category_list">
                         <Link to={value.link}>{value.category}</Link>
                     </div>
                     <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                 </div>
                 <div className="portfolio_hover">
                     <p>{value.description}</p>
                 </div>
             </div>
         </div>
         <Link className="transparent_link" to={value.link}></Link>
     </div>
 </div>
))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioFreelance;