// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";

import DesignerPortfolio from "./home/DesignerPortfolio";

//Portfolio Personal
import Copera19 from "./elements/portafolio-personal/Copera19";
import TneTimes from "./elements/portafolio-personal/TneTimes";
import PokerEnglish from "./elements/portafolio-personal/PokerEnglish";

//Portfolio Clientes
import FernyLlantas from "./elements/portafolio-clientes/FernyLlantas";
import Emerymark from "./elements/portafolio-clientes/Emerymark";

// Dark Home Layout
import DarkPortfolioLanding from "./dark/PortfolioLanding";

import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={DarkPortfolioLanding}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`}
            component={DarkPortfolioLanding}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/designer-portfolio`}
            component={DesignerPortfolio}
          />

          {/*Portfolio Personal */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/copera-19`}
            component={Copera19}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/the-new-earth-times`}
            component={TneTimes}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pokerenglish`}
            component={PokerEnglish}
          />

          {/*Portfolio Clientes */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ferny-llantas`}
            component={FernyLlantas}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/emerymark`}
            component={Emerymark}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pokerenglish`}
            component={PokerEnglish}
          />

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
