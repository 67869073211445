import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const Portfolio_image = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665700248/default_utils_images/fondoInicio_1_qqkdbz.png" alt="React Creative Agency" />;
const Portfolio_image2 = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665700224/default_utils_images/fondoInicio_z1sxuh.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665700204/default_utils_images/poker1_lqifwh.png" alt="React Creative Agency"  />;
const Portfolio_image4 = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665700203/default_utils_images/gaia1_an5up8.png" alt="React Creative Agency"  />;
const Portfolio_image5 = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665700201/default_utils_images/gedisa1_gekfa9.png" alt="React Creative Agency"  />;
const Portfolio_image6 = <img style={{height:440}} src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665700195/default_utils_images/tinder1_lqqqyz.png" alt="React Creative Agency"  />;
const Portfolio_image7 = <img style={{height:440}}  src="https://res.cloudinary.com/thenewearthtimes/image/upload/f_auto,q_auto/v1665699286/default_utils_images/Captura_de_Pantalla_2022-10-13_a_la_s_17.13.56_aqgrix.png" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-static-09.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;



const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'National winning project',
        title: 'Copera-19',
        description: 'Web and mobile platform to ask for social help',
        link:'/copera-19'
    },
    {
        image: Portfolio_image6,
        category: 'Web and tinder style app to adopt dogs',
        title: 'Be My Dog',
        description: 'Tinder style app to adopt dogs',
        link:'bemydog.app',
        openTab:true

    },
    {
        image: Portfolio_image7,
        category: 'CTO and Co-Founder',
        title: 'Animarama',
        description: 'Ecommerce and directory specialized in pets and animals',
        link:'animarama.com.mx',
        openTab:true

    },
    {
        image: Portfolio_image2,
        category: 'Browser extension',
        title: 'The New Earth Times',
        description: 'Feed and social network of news related to the earth',
        link:'/the-new-earth-times'

    },
    {
        image: Portfolio_image3,
        category: 'Online education platform',
        title: 'Poker English',
        description: 'Learning platform for students and teachers',
        link:'/pokerenglish'

    },
    {
        image: Portfolio_image4,
        category: 'Software company',
        title: 'GaiaSoft',
        description: 'Software development and services',
        link:'/'

    },
    {
        image: Portfolio_image5,
        category: 'Whatsapp-Artificial Intelligence for first aid',
        title: 'Chatbot Gedisa',
        description: 'Project initially supported by Gedisa',
        link:'/'

    },
    // {
    //     image: Portfolio_image6,
    //     category: 'Web and tinder style app to adopt dogs',
    //     title: 'Be My Dog',
    //     description: 'Tinder style app to adopt dogs',
    //     link:'/'

    // }
]


class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (

                   value.link === '/' ?

                   <div className={`${column}`} key={index} onClick={()=>{
                    Swal.fire({
                        title: `${value.title === 'GaiaSoft' ?
                        'Gaia Soft was a venture that lasted 6 months which was a company dedicated to software services and product creation, a company in which I was co-founde'
                        :

                        'This project is finished however the link of the project is under maintenance, the information will be available very soon'}`
                      
                    })
                   }}>
                   <div className="im_portfolio">
                       <div className="thumbnail_inner">
                           <div className="thumbnail">
                               <a>
                                   {value.image}
                               </a>    
                           </div>
                       </div>
                       <div className="content">
                           <div style={{cursor:'pointer'}} className="inner">
                               <div className="portfolio_heading">
                                   <div className="category_list">
                                       <a>{value.category}</a>
                                   </div>
                                   <h4 className="title"><a>{value.title}</a></h4>
                               </div>
                               <div className="portfolio_hover">
                                   <p >{value.description}</p>
                               </div>
                           </div>
                       </div>
                     
                   </div>
               </div>





                   :

                  value.openTab ?

                  <div className={`${column}`} key={index} onClick={(e)=>{
                      e.preventDefault()

                      window.open("http://"+value.link)
                 
                   }}>
                   <div className="im_portfolio">
                       <div className="thumbnail_inner">
                           <div className="thumbnail">
                               <a>
                                   {value.image}
                               </a>    
                           </div>
                       </div>
                       <div className="content">
                           <div style={{cursor:'pointer'}} className="inner">
                               <div className="portfolio_heading">
                                   <div className="category_list">
                                       <a>{value.category}</a>
                                   </div>
                                   <h4 className="title"><a>{value.title}</a></h4>
                               </div>
                               <div className="portfolio_hover">
                                   <p >{value.description}</p>
                               </div>
                           </div>
                       </div>
                     
                   </div>
               </div>



                  :

            

                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to="/portfolio-details">
                                        {value.image}
                                    </Link>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={value.link}>{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={value.link}></Link>
                        </div>
                    </div>
               
               
               ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;