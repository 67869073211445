import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ProgressBar } from 'react-bootstrap';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main Skills",
        tab2 = "Competitions",
        tab3 = "Experience",
        tab4 = "Education";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                                <div className="single-progress">
                                                    <h6 className="title">Software development and architecture</h6>
                                                    <ProgressBar now={95} />
                                                    <span className="label">95%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Product development</h6>
                                                    <ProgressBar now={75} />
                                                    <span className="label">90%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Entrepreneurship</h6>
                                                    <ProgressBar now={85} />
                                                    <span className="label">85%</span>
                                                </div>

                                                

                                                <div className="single-progress">
                                                    <h6 className="title">Project Planning</h6>
                                                    <ProgressBar now={80} />
                                                    <span className="label">90%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">IT area and tech teams leadership</h6>
                                                    <ProgressBar now={80} />
                                                    <span className="label">90%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>


                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Contest #PonteLaVerdeConElCovid <span>- 1st place nationally</span></a> 2020
                                               </li>
                                               <li>
                                                   <a href="/service">HACK ATTACK IBM  <span>- 1st place state - Storage Category</span></a> 2019
                                               </li>
                                               <li>
                                                   <a href="/service">Marathon Behind the Code IBM  <span>- participe</span></a> 2020
                                               </li>

                                               <li>
                                                   <a href="/service">Hackaton Talent Land - Guadalajara- Astrazeneca Track <span>- participe</span></a> 2022
                                               </li>
                                              
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>

                                           <li>
                                                   <a href="/service">Copera-19 <span> - Founder and Developer</span> </a> 2020 - 2021
                                               </li>
                                               <li>
                                                   <a href="/service">Freelance Full Stack Developer <span> - more than 5 years of experience</span></a> 2016 - 2021
                                               </li>

                                               <li>
                                                   <a href="/service">Be My Dog <span> - Founder and Developer</span> </a> 2021 - Current
                                               </li>

                                               
                                               <li>
                                                   <a href="/service">The New Earth Times <span> - Founder and Developer</span> </a> 2021
                                               </li>

                                               <li>
                                                   <a href="/service">Google Cloud Architect <span> - Inbest Cloud</span> </a> 2022
                                               </li>

                                               

                                               <li>
                                                   <a href="/service">CTO and Co-Founder <span> - Animarama</span></a> 2022
                                               </li>
                                               <li>
                                                   <a target='_blank'  href="https://firebasestorage.googleapis.com/v0/b/pruebas-6019b.appspot.com/o/LUIS%20EDUARDO%20romo%20fernandez%20(1).pdf?alt=media&token=9b6bc132-22c4-40c5-acd1-e450dac21bb3">For more information i recommend you to see my CV by clicking here</a> 
                                               </li>
                                               
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                           <li>
                                                   <a href="">Informatics Engineering<span> - Self-taught- (youtube videos, udemy, platzi, etc...)</span></a> 2015-Current 
                                               </li>

                                               <li>
                                                   <a href="">Electric mechanic engineering<span> - University Center of Exact Sciences (Cucei-University of guadalajara) - Jalisco / Mexico</span></a> 2017-2020 
                                               </li>

                                               </ul>
                                              
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;